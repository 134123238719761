import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/9.png"
import Jaroslav from "../images/photos/jaroslav.jpg"

const LECTURER = [
  {
    src: Jaroslav,
    alt: "",
    personName: "Jaroslav Vojna",
    text: [
      "Jardu baví hledat odpovědi na byznysové otázky v datech a uvádět poznatky (od CX po marketing) do praxe. Primárně se zaměřuje na interpretační analytiku – webovou, produktovou a zákaznickou.",
      `Má zkušenosti z řady mezinárodních projektů, například Avastu a Footshopu. V současnosti analyzuje „SaaSová“ data v Digismoothie.`,
    ],
    linkedInProfile: "//linkedin.com/in/jaroslav-vojna-39b3961b",
  },
]

const PohledProduktu: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper
        title="Pohled produktu – AB testování + CRO"
        ogImage={OgImage}
      />

      <SyllabusHeader
        orangeText="Pohled produktu –"
        blackText="AB testování + CRO"
        numberOfLesson="9/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          postup – prioritizace
          <div>identifikace potenciálně problémového místa</div>
          <div>spočítání si jednoduchého business case</div>
          <div>design experimentu</div>
          <div>samotné provedení</div>
        </div>
        <div>prerekvizity a na co si dát pozor</div>
        <div>tooling</div>
        <div>AB testování na backend</div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default PohledProduktu
