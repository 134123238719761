import Image1 from "../images/syllabus-og-images/1.png"
import Image2 from "../images/syllabus-og-images/2.png"
import Image3 from "../images/syllabus-og-images/3.png"
import Image4 from "../images/syllabus-og-images/4.png"
import Image5 from "../images/syllabus-og-images/5.png"
import Image6 from "../images/syllabus-og-images/6.png"
import Image7 from "../images/syllabus-og-images/7.png"
import Image8 from "../images/syllabus-og-images/8.png"
import Image9 from "../images/syllabus-og-images/9.png"
import Image10 from "../images/syllabus-og-images/10.png"
import Image11 from "../images/syllabus-og-images/11.png"
import Image12 from "../images/syllabus-og-images/12.png"

export const SYLLABUS_SLIDER = [
  {
    imageSrc: Image1,
    name: "1/13",
    position: "Návrh měření",
    linkToSlide: "/navrh-mereni",
  },
  {
    imageSrc: Image2,
    name: "2/13",
    position: "Konfigurace GA4 a její specifika",
    linkToSlide: "/konfigurace-ga4-a-jeji-specifika",
  },
  {
    imageSrc: Image3,
    name: "3/13",
    position: "Nastavování reportování v GA4",
    linkToSlide: "/nastaveni-reportingu-v-ga4",
  },
  {
    imageSrc: Image4,
    name: "4/13",
    position: "Jak na dokumentaci",
    linkToSlide: "/jak-na-dokumentaci",
  },
  {
    imageSrc: Image5,
    name: "5/13",
    position: "BigQuery a SQL",
    linkToSlide: "/bigquery-a-sql",
  },
  {
    imageSrc: Image6,
    name: "6/13",
    position: "Privacy a sběr 1st party dat",
    linkToSlide: "/privacy-a-sber-1st-party-dat",
  },
  {
    imageSrc: Image7,
    name: "7/13",
    position: "Datová architektura",
    linkToSlide: "/datova-architektura",
  },
  {
    imageSrc: Image8,
    name: "8/13",
    position: "Pohled marketéra: Vyhodnocování",
    linkToSlide: "/pohled-marketera-vyhodnocovani",
  },
  {
    imageSrc: Image9,
    name: "9/13",
    position: "Pohled produktu: AB testování + CRO",
    linkToSlide: "/pohled-produktu-cro-ab-testovani",
  },
  {
    imageSrc: Image10,
    name: "10/13",
    position: "Server side měření a jeho specifika",
    linkToSlide: "/server-side-mereni-a-jeho-specifikai",
  },
  {
    imageSrc: Image11,
    name: "11/13",
    position: "Zavádění datové kultury do firmy",
    linkToSlide: "/zavadeni-datove-kultury",
  },
  {
    imageSrc: Image12,
    name: "12/13",
    position: "Marketing Mix Modelling",
    linkToSlide: "/marketing-mix-modelling",
  },
]
