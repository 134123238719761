import {
  InnerFloeContainer,
  Slider,
  TopBottomFloeWrapper,
} from "@igloonet-web/shared-ui"
import React from "react"

type Props = {
  data: Array<{
    name?: string
    imageSrc: string
    linkedin: string
    position: string
    text: Array<string>
  }>
}

const SyllabusSlider: React.FC<Props> = ({ data }) => {
  return (
    <TopBottomFloeWrapper>
      <InnerFloeContainer>
        <Slider
          title="Další lekce"
          data={data}
          style={{ minHeight: "25rem" }}
        />
      </InnerFloeContainer>
    </TopBottomFloeWrapper>
  )
}

export default SyllabusSlider
